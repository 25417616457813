<template>
<div class="invite">
  <Navbar title="邀请好友" needBack :needRight="false" background="white"></Navbar>
    <div class="invite-bk">
      <div style="display:none">{{text}}</div>
      <div class="invite-container">
        <div class="top-fold"></div>
        <div class="invite-title">INVITAION CODE</div>
        <div class="invite-title-text">专属邀请码<div class="invite-title-text-bk"></div></div>
          <!-- 验证码 -->
          <div class="row-center captcha_input_wrapper">
            <div
              v-for="(item,index) in captchas"
              :key="index"
              :id="'captcha'+index"
              class="captcha_input_box row-center"
            >
            {{item.num}}
            </div>

          </div>

          <div class="input-bottom">
            <div v-for="(item,index) in captchas" :key="index + 'x'" class="input-bottom-line"></div>
          </div>
        
        <div class="invite-cv-text"> <span class="invite-cv-text-emphasis" >*</span> 复制邀请码发送给朋友，登录并输入即可开始使用</div>

        <button class="cv-btn" :data-clipboard-text="text" @click="fz">点击复制</button>
        <div class="cv-text">有效期30天</div>


      </div>
    </div>
    <!-- <div class="row-center captcha_input_wrapper">
      <input
        v-for="(item,index) in captchas"
        :key="index"
        v-model="item.num"
        :id="'captcha'+index"
        @input="inputFinash(index)"
        @focus="adjust(index)"
        @keydown="inputDirection(index)"
        class="captcha_input_box row-center"
        type="tel"
        maxlength="1"
      />
    </div>
    <div @click="fz">点击复制</div> -->

</div>
 
</template>

<script>
import Clipboard from "clipboard";

export default {
  components: {
			Clipboard
		},
  data() {
    return {
      // 当前输入框
      activeInput: 0,
      captchas: [
        { num: "*" },
        { num: "*" },
        { num: "*" },
        { num: "*" },
        { num: "*" },
        { num: "*" },
      ],
      title:'邀请好友',
      text:''
    };
  },
  mounted(){
    this.getInvite()
  },
 
  methods: {
    //获取邀请码
    getInvite(){
      this.$api.my.addInvite({userId:localStorage.id}).then(res=>{
        console.log(res);
        if(res.code === 200){
          this.text = res.data.code
        let code = res.data.code.split('')
          this.captchas.forEach((v,i)=>{
            this.captchas[i].num = code[i]
          })
        }
      })
    },
      //复制
      fz(){
          // let Copy = ''
          // this.captchas.forEach(v=>{
          //     Copy += v.num
          // })
          //  var oInput = document.createElement("input");   //创建一个input标签
          //   oInput.value = Copy;  //将要复制的值赋值给input
          //   document.body.appendChild(oInput);  //在页面中插入
          //   oInput.select(); // 模拟鼠标选中
          //   document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
          //   oInput.style.display = "none";  //
          //   this.$toast('复制成功')


				    let clipboard = new Clipboard(".cv-btn");
				    console.log(clipboard)
				    clipboard.on("success", e => {
				    	// 释放内存
				    	clipboard.destroy();
              this.$toast('复制成功')

				    });
				    clipboard.on("error", e => {
				    	// 不支持复制
              this.$toast('该浏览器不支持自动复制')
				    	// 释放内存
				    	clipboard.destroy();
				    });



      },

      //去我的页面
      goMy(){
        this.$router.push('/my')
      }
   
  },
};
</script>
<style lang='less' scoped>
// .row-center {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// }
// .captcha_input_wrapper {
//     margin-left: 10%;
//   width: 80%;
// }
// .captcha_input_box {
//   width: 10vw;
//   height: 10vw;
//   margin-right: 12px;
//   background: rgba(255, 255, 255, 1);
//   border-radius: 6px;
//   border: 1px solid #dddddd;
//   font-size: 8vw;
//   text-align: center;
//   color: #1e243a;
// }

.header{
    position: fixed;
    top: 0;
    left: 0;        
    display: flex;
    justify-content: space-between;
    padding: .2rem .3rem;
    width: 100%;
    height: 1rem;
    font-size: .36rem;
    box-sizing: border-box;
    background-color: #fff;
        .back-icon{
            width: .2rem;
            height: .38rem;
        }
        .header-text{
            display: flex;
            align-items: center;
            font-size: .36rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
        }
}

.invite{
 
  width: 100%;
  height: 100vh;
  background-color: #F5F7FB;
  box-sizing: border-box;
  
  .invite-bk{
    position: relative;
    width: 100%;
    height: 10.5rem;
    background: url('../../assets/images/inviteBk.png') no-repeat;
    background-size:100%;

    .invite-container{
      padding-top: 1.4rem;
      margin: auto;
      width: 5.72rem;
      height: 6.96rem;

        .top-fold{
         margin: .46rem auto 0 ;
         width: .4rem;
         height: .04rem;
         background: #CFD5E0;
         border-radius: .02rem;
        }
        .invite-title{
          margin: .14rem 0 .2rem;
          text-align: center;
          font-size: .5rem;
          font-family: SF Pro Display;
          font-weight: bold;
          color: #CFD5E0;
          Letter-spacing:-.04rem
        }
        .invite-title-text{
          position: relative;
          text-align: center;
          font-size: .36rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .invite-title-text-bk{
            position: absolute;
            top: .26rem;
            left: 50%;
            transform: translateX(-50%);
            width: 2.06rem;
            height: .22rem;
            background: #8A9DFF;
            opacity: 0.3;
          } 

        }
        //验证码
        .row-center {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          margin: .34rem .08rem 0;
        }
        .captcha_input_wrapper {
          padding: 0 .1rem;
        }
        .captcha_input_box {
          position: relative;
          margin: 0 .2rem;
          border: none;
          background-color: transparent;
          font-size: .84rem;
          font-family:-apple-system, BlinkMacSystemFont,  SF Pro Display;
          font-weight: 500;
          color: #3B5BFF;
            &::before{
              position: absolute;
              top: 1.18rem;
              content: '';
              width: .52rem;
              height: .02rem;
              background: #DAE8FF;
              border-radius: .01rem;
            }
        }
        // .input-bottom{
        //   display: flex;
        //   margin-left: .06rem;
        //   .input-bottom-line{
        //       margin:0 .2rem;
        //       width: .52rem;
        //       height: .02rem;
        //       background: #DAE8FF;
        //       border-radius: .01rem;
        //   }
        // }

        .invite-cv-text{
          margin: .4rem auto .1rem;
          width: 4.28rem;
          text-align: center;
          font-size: .26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3B5BFF;
          .invite-cv-text-emphasis{
            color: #FF0000;
          }
        }

        .cv-btn{
          margin-left: 50%;
          transform: translateX(-50%);
          width: 2rem;
          height: .76rem;
          background: linear-gradient(0deg, #3B5BFF, #7399F5);
          border-radius: .38rem;
          font-size: .32rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
        .cv-text{
          text-align: center;
          margin-top: .1rem;
          font-size: .24rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #AEAEAE;
        }


    }

  
  }
  
}
</style>
